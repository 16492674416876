import { createGlobalStyle } from 'styled-components';
import {
  black,
  blue,
  green,
  nHaas55,
  nHaas75,
  nHaasTx55,
  offWhite,
  red,
  solaire,
  white,
} from '../../../../styles/theme';
import 'bootstrap/dist/css/bootstrap.css';

let height;
if (typeof window !== 'undefined') {
  height = window.innerHeight - 198;
}

export const GlobalStyle = createGlobalStyle<{ hideIntercom: boolean }>`
  html {
    background-color: ${offWhite};
    body {
      overflow-x: hidden;
      background-color: ${offWhite};
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizelegibility;
      text-size-adjust: 100%;
      color:${black};
      font-family: ${nHaas55};
      a {
        transition: all .2s;
        color:${black};
        text-decoration: none;
        &:hover, &:active{
          color: ${green};
        }
      }
      .modal-dialog{
        transform: none;
        min-height: 80%;
        display: flex;
        align-items: center;
        .modal-content{
          background-color: ${offWhite};
        }
      }
      .black-friday-popup {
        .modal-dialog {
          max-width: 445px;
          min-height: 100%;
          margin: auto;
          @media only screen and (max-width: 500px) { 
            padding: 0 15px;
            max-width: 365px;
          }
        }
      }
    }

    @media only screen and (max-width: 450px) {
      .intercom-lightweight-app {
        .intercom-lightweight-app-launcher {
          bottom: 70px;
          right: 10px;
        }
      }
      #intercom-container {
        .intercom-app {
          .intercom-launcher-frame {
            bottom: 70px;
            right: 10px;
          }
        }
      }
    }

    ${(props) => props.hideIntercom && `
      .intercom-lightweight-app, #intercom-container {
        display: none;
      }
    `}

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    .svg-selected {
      fill: #F5785A;
    }

    .btn {
      cursor: pointer;
      min-width: 180px;
      border-radius: 0;
      font-family: ${nHaas55};
      font-size: 18px;
      border-width: 1.5px;
      padding: 10px 0;
      color: ${black};

      &.btn-orange {
        padding-top: 12px;
        padding-bottom: 12px;
        border-style: solid;
        border-width: 1.5px;
        border-color: ${black};
        background-color: ${red};
        color: ${black};
      }
    
      &.btn-red {
        background-color: ${red};
        border-color: ${black};
    
        &:hover {
          box-shadow: none !important;
          background-color: ${green} !important;
          border-color: ${green} !important;
          color: ${black} !important;
    
          @media only screen and (max-width: 575px) {
            background-color: ${red} !important;
          }
        }
    
        &:focus {
          box-shadow: none !important;
          // border-color: ${green} !important;
          color: ${black} !important;
        }
        &:active {
          box-shadow: none !important;
          color: ${black} !important;
        }
      }
    
      &.btn-green {
        background-color: ${green};
        border-color: ${black};
    
        &:hover,
        &:focus,
        &:active {
          box-shadow: none !important;
          background-color: ${red} !important;
          border-color: ${red} !important;
          color: ${black} !important;
        }
      }
    
      &.btn-sm {
        min-width: 160px;
        font-size: 16px;
        padding: 9px 15px;
        background-position: 111% center;
      }
      &.btn-primary {
        &:focus {
          box-shadow: none !important;
        }
      }
    }

    #nprogress {
      .bar {
        height: 4px;
        background: ${green};
      }

      .peg {
        box-shadow: 0 0 10px ${green}, 0 0 5px ${green};
      }
    }

    #nf-notify.notify-warning {
      background: ${red};
    }

    #nf-notify.notify-success {
      background: ${green};
    }

    #nf-notify {
      display: flex;
      color: ${white};
      overflow: hidden;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      justify-content: center;
      font-size: 11px;
      font-family: ${nHaas55};
      z-index: 99999;
      height: 0;
      transition: 0.5s height ease-in-out;

      i {
        vertical-align: middle;
        font-size: 18px;
        width: 18px;
        margin-top: -2px;
        margin-right: 3px;
      }
      .warn,
      .warn::before,
      .warn::after
      {
        position: relative;
        padding: 0;
        margin: 0;
      }

      .warn {
        font-size: 20px;
        color: transparent;
      }

      .warn.warning {
        display: inline-block;
        top: 8px;
        width: 1.15em;
        height: 1.15em;
        overflow: hidden;
        border: none;
        border-radius: 0.625em;
        margin-right: 5px;
      }

      .warn.warning::before {
        content: "";
        display: block;
        top: -0.08em;
        left: 0;
        position: absolute;
        border: .6em solid transparent;
        border-bottom-color: ${white};
        border-bottom-width: 1em;
        border-top-width: 0;
      }

      .warn.warning::after {
        display: block;
        position: absolute;
        top: -2px;
        left: 1px;
        width: 100%;
        padding: 0 1px;
        text-align: center;
        content: "!";
        font-size: 16px;
        font-weight: bold;
        color: ${red};
      }

      .success-icon {
        display: inline-block;
        transform: rotate(45deg);
        height: 16px;
        width: 7px;
        border-bottom: 2px solid ${white};
        border-right: 2px solid ${white};
        margin-right: 10px;
      }

      p {
        font-size: 14px;
        font-family: ${nHaas55};
        color: ${black};
        margin: 10px 0;
        display: inline-block;

        @media only screen and (max-width: 450px) {
          font-size: 10px;
        }
      }
    }

    .ff-tx55 {
      font-family: ${nHaasTx55} !important;
    }

    .fs-m-12-d-14 {
      font-size: 14px !important;
      @media screen and (max-width: 992px) {
        font-size: 12px !important;
      }
    }

    .red {
      color: ${red} !important;
    }

    .grecaptcha-badge {
      opacity: 0;
    }
  }
  @font-face {
    font-family: 'Homemade Apple';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/fonts/Homemade-Apple.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  @font-face {
    font-display: swap;
    font-family: 'NHaasGroteskDSPro-55Rg';
    src: url('/fonts/NHaasGroteskDSPro-55Rg.eot');
    src: url('/fonts/NHaasGroteskDSPro-55Rg.eot?#iefix') format('embedded-opentype'), url('/fonts/NHaasGroteskDSPro-55Rg.woff2') format('woff2'),
        url('/fonts/NHaasGroteskDSPro-55Rg.woff') format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'NHaasGroteskDSPro-65Md';
    src: url('/fonts/NHaasGroteskDSPro-65Md.eot');
    src: url('/fonts/NHaasGroteskDSPro-65Md.eot?#iefix') format('embedded-opentype'), url('/fonts/NHaasGroteskDSPro-65Md.woff2') format('woff2'),
        url('/fonts/NHaasGroteskDSPro-65Md.woff') format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'NHaasGroteskTXPro-75Bd';
    src: url('/fonts/NHaasGroteskTXPro-75Bd.eot');
    src: url('/fonts/NHaasGroteskTXPro-75Bd.eot?#iefix') format('embedded-opentype'), url('/fonts/NHaasGroteskTXPro-75Bd.woff2') format('woff2'),
        url('/fonts/NHaasGroteskTXPro-75Bd.woff') format('woff');
  }
  @font-face {
    font-display: swap;
    font-family: 'SolaireDT-Regular';
    src: url('/fonts/SolaireDT-Regular.eot');
    src: url('/fonts/SolaireDT-Regular.eot?#iefix') format('embedded-opentype'), url('/fonts/SolaireDT-Regular.woff2') format('woff2'),
      url('/fonts/SolaireDT-Regular.woff') format('woff'), url('/fonts/SolaireDT-Regular.ttf') format('truetype'),
      url('/fonts/SolaireDT-Regular.svg') format('svg');
  }

  @font-face {
    font-display: swap;
    font-family: 'Inter';
    src: url('/fonts/Inter.ttf') format('truetype');
  }
  @font-face {
    font-display: swap;
    font-family: 'InterLight';
    src: url('/fonts/Inter-Light.ttf') format('truetype');
  }
  @font-face {
    font-display: swap;
    font-family: 'InterRegular';
    src: url('/fonts/Inter-Regular.ttf') format('truetype');
  }
  @font-face {
    font-display: swap;
    font-family: 'InterBold';
    src: url('/fonts/Inter-SemiBold.ttf') format('truetype');
  }

  @font-face {
    font-display: swap;
    font-family: 'NHaasGroteskTXPro-56It';
  }

  @font-face {
    font-display: swap;
    font-family: 'NHaasGroteskTXPro-65Md';
    src: url('/fonts/NHaasGroteskTXPro-65Md.eot');
    src: url('/fonts/NHaasGroteskTXPro-65Md.eot?#iefix') format('embedded-opentype'), url('/fonts/NHaasGroteskTXPro-65Md.woff2') format('woff2'),
      url('/fonts/NHaasGroteskTXPro-65Md.woff') format('woff');;
  }
  
  @font-face {
    font-display: swap;
    font-family: 'NHaasGroteskTXPro-55Rg';
    src: url('/fonts/NHaasGroteskTXPro-55Rg.eot');
    src: url('/fonts/NHaasGroteskTXPro-55Rg.eot?#iefix') format('embedded-opentype'), url('/fonts/NHaasGroteskTXPro-55Rg.woff2') format('woff2'),
      url('/fonts/NHaasGroteskTXPro-55Rg.woff') format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'NHaasGroteskTXPro-55Rg';
    src: url('/fonts/NHaasGroteskTXPro-55Rg.eot');
    src: url('/fonts/NHaasGroteskTXPro-55Rg.eot?#iefix') format('embedded-opentype'), url('/fonts/NHaasGroteskTXPro-55Rg.woff2') format('woff2'),
      url('/fonts/NHaasGroteskTXPro-55Rg.woff') format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Neue haas Grotesk';
    src: url('/fonts/NHaasGroteskTXPro-65Md.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-display: swap;
    font-family: 'Neue haas Grotesk';
    src: url('/fonts/NHaasGroteskDSPro-55Rg.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'September';
    src: url('/fonts/September-Spirit.otf'), url('/fonts/September-Spirit-Extras.otf'), url('/fonts/September-Spirit-Alt.otf'), url('/fonts/September-Spirit-All-Caps.otf');
  }

	@font-face {
		font-family: 'Solaire';
		src: url('/fonts/SolaireDT-Regular.ttf');
		font-weight: 400;
    font-style: normal;
	}

	@font-face {
    font-family: 'Inter';
    src: url('/fonts/Inter-Regular.ttf');
		font-weight: 400;
    font-style: normal;
  }

	@font-face {
    font-family: 'Inter';
    src: url('/fonts/Inter-Light.ttf');
		font-weight: 300;
    font-style: normal;
  }

	@font-face {
    font-family: 'Inter';
    src: url('/fonts/Inter-Medium.ttf');
		font-weight: 500;
    font-style: normal;
  }

	@font-face {
    font-family: 'Inter';
    src: url('/fonts/Inter-SemiBold.ttf');
		font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Ammer Handwriting';
    src: url('/fonts/AmmerHandwritingWeb.ttf');
		font-weight: 400;
    font-style: normal;
  }

  .w-button {
    display: inline-block;
    padding: 9px 15px;
    background-color: ${blue};
    color: white;
    border: 0;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0;
  }
  .modal {
    z-index: 9999;
    background: rgba(39,38,38,.6);
    align-items: center;
    .modal-content {
      // padding: 1rem;
      border-radius: 0;
      border: none;
      text-align: center;
    }
    .modal-85-percent {
      min-width: 100%;
      margin: 0;
      top: 198px;
      min-height: 0;
    }

    // @media (max-width: 1123px) {
    //   .modal-85-percent {
    //     top: 0px;
    //   }
    // } 

    .modal-how-does-it-work-of-review {
      max-width: none !important;
      width: 546px;
      margin: 1.75rem auto;
      @media (min-width: 375px) and (max-width: 768px) {
        width: 364px; 
      } 
      @media (max-width: 374px) {
        width: 280px;
      }
    }
    .content-modal-how-dose-it-work-of-review {
      border-radius: 15.6px;
    }

    .modal-784w {
      max-width: none !important;
      @media (min-width: 768px) {
        width: 616px !important;
      }
      @media (max-width: 767px) and (min-width: 560px) {
        width: 529px !important;
        margin:1.75rem auto;
        .modal-content {
          .thankyou {
            padding: 64px;
          }
        }
      }
      @media (max-width: 559px) {
        max-width: 288px !important;
        margin: 1.75rem auto;
        .modal-content {
          .thankyou {
            padding: 29px;
          }
        }
      }
    }

    @media (min-width: 768px){
      .modal-90w {
        max-width: 860px;
      }
    }
    .show-modal-content-products {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      align-items: center;
      @keyframes slidePopupUp {
        from {
            bottom: -${height}px;
        }
        to {
            bottom: 0
        }
      }
      animation: slidePopupUp linear 0.3s;
    }
    .close-modal-content-products {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      align-items: center;
      @keyframes slidePopupDown {
        from {
            bottom: 0;
        }
        to {
            bottom: -${height}px;
        }
      }
      animation: slidePopupDown linear 0.3s;
    }
    .modal-90w {
      .modal-footer {
        padding: 0 30px 25px 30px;
        justify-content: flex-end;
      
        p {
          margin-bottom: 0;
          font-size: 20px;
          margin-left: 45px;
        }
        p:last-child {
          width: 85px;
        }
        p span {
          font-family: ${nHaas75};
        }
      }
    }
    @media only screen and (max-width: 767px) {
      .modal-90w {
        margin: 0;
        max-width: 100%;
        .modal-content {
          padding-bottom: 55px;
        }
      }
    }

    .high-risk {
      @media (min-width: 576px) {
        max-width: 545px;
      }
    }
    .modal-footer {
      border: none;
      
      button {
        display: block;
      }
    }
    .question-pause {
      @media (min-width: 576px) {
        max-width: 450px;
      }
    }
    .large .medium {
      @media only screen and (max-width: 992px) {
        max-width: 96%;
        margin: 1rem auto;
      }
    }

    .large {
      max-width: 950px;
    }

    .medium {
      max-width: 750px;
    }

    .label-popup {
      max-width: 750px;
      margin-right: auto;
      margin-left: auto;

      img {
        width: 100%;
      }

      @media only screen and (max-width: 1024px) {
        max-width: 96%;
        height: 100%;
      }
    }
    
    .eofy-popup {
      max-width: 750px;
      margin-right: auto;
      margin-left: auto;
      
      .modal-content {
        border-radius: 30px;
      }

      @media only screen and (max-width: 1024px) {
        max-width: 300px;
        border-radius: 26px;
        height: 100%;
      }
    }

    .pgw-popup {
      margin-right: auto;
      margin-left: auto;
      .modal-content {
        border-radius: 16px;
      }
    }

    .after-pay {
      max-width: 774px;
      margin-right: auto;
      margin-left: auto;

      @media only screen and (max-width: 992px) {
        max-width: 414px;
        height: 100%;
      }

      @media only screen and (max-width: 414px) {
        max-width: 100%;
      }
    }
    .referral-trigger-a-popup {
      max-width: 820px;
      margin-right: auto;
      margin-left: auto;

      .modal-content {
        border-radius: 32px;
      }

      @media only screen and (max-width: 1024px) {
        max-width: 375px;
        height: 100%;

        .modal-content {
          border-radius: 16px 16px 0px 0px;
        }
      }

      @media only screen and (max-width: 375px) {
        max-width: 350px;
      }
    }
  }
  .promo-applied-warning {
    z-index: 19999;
  }
  .text-right {
    text-align: right!important;
  }

  .sticky-bottom-bar {
    display: flex;
    align-items: center;

    width: 100%;
    background: #f7f4eb;
    border-top: 1px solid ${black};
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999;
    justify-content: space-between;

    h3 {
      font-size: 32px;
      margin-bottom: 0;
    }

    span {
      font-size: 32px;
      font-family: ${solaire};
      display: inline-block;
      line-height: 1;
    }

    .left {
      padding-left: 40px;
    }

    .right {
      display: flex;
      align-items: center;

      span {
        padding: 3px 20px;
      }
    }
  }

  .modal-r12 {
    border-radius: 12px !important;
  }
  .modal-bg-white {
    background-color: #fff !important;
  }
  .modal-w550 {
    width: 550px;
    @media (max-width: 600px) {
      width: auto;
    }
  }

  .pac-container { z-index: 100000 !important; }

`;
export default GlobalStyle;
